@use "sass:list"; // https://sass-lang.com/documentation/breaking-changes/slash-div

//=================================================================================================
// HTML base
//=================================================================================================
html {
    font: $font-style $font-variant $font-weight list.slash(#{$font-size}, #{$line-height}) $font-family;
    font-size: $font-size;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // If need https://caniuse.com/?search=scroll-behavior%3A%20smooth
    // scroll-behavior: smooth;
}

//=================================================================================================
// Body base
//=================================================================================================
body {
    font-size: $font-size--text;
    color: $color-text;
    background: $color-bg--body;
}

ol,
ul {
    list-style-type: none;
}

p,
ol,
ul,
dl,
table {
    margin: 0 0 0 0;
    padding: 0;
}

//=================================================================================================
// Remove extra margin for nested lists
//=================================================================================================
ul li ul {
    margin-bottom: 0;
}

ol li ol {
    margin-bottom: 0;
}

//=================================================================================================
// Headings
//=================================================================================================
h1,
h2,
h3,
h4,
h5 {
    font-family: $font-family--heading;
    font-weight: $font-weight--heading;
    line-height: $line-height--heading;
}

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
    margin: 25px 0 10px;
}

.wysiwyg h2 {
    margin: 0px 0px 30px;
    padding: 5px 0;
    border-bottom: $border-width $border-style;
}

//=================================================================================================
// Heading individual styles
//=================================================================================================
h1 {
    font-size: $font-size--1;
}

h2,
.gform_wrapper form h2.gsection_title,
.title-newsletter-h2 {
    font-family: $font-family;
    font-size: toRem(34);
    font-weight: $font-weight-bold;
    line-height: toRem(36);
    color: $color-dark;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: -14px;
        left: -9px;
        z-index: 0;
        width: 31px;
        height: 33px;
        background-image: url($urlSpriteImage + "/theme-icons/CSS/bulle_h2.svg");
        background-repeat: no-repeat;
        background-size: contain;
        z-index: -1;
    }
}

h3 {
    font-family: $font-family;
    font-size: toRem(28);
    font-weight: $font-weight-bold;
    line-height: toRem(30);
    color: $color-dark;
}

body:not(.home) {
    h3 {
        position: relative;

        &::after {
            background-image: url($urlSpriteImage + "/theme-icons/CSS/bulle_h3.svg");
            content: "";
            position: absolute;
            bottom: 13px;
            left: -8px;
            width: 22px;
            height: 23px;
            background-repeat: no-repeat;
            background-size: contain;
            z-index: -1;
        }
    }
}

h4 {
    font-family: $font-family;
    font-size: toRem(22);
    font-weight: $font-weight-bold;
    line-height: toRem(24);
    color: $color-dark;
}

h5 {
    font-size: $font-size--5;
}

h6 {
    font-size: $font-size--6;
}

//=================================================================================================
// Pictures styling
//=================================================================================================
//img:before {
//}

//=================================================================================================
// Link styling
//=================================================================================================
a {
    color: inherit;
    text-decoration: none;
    transition: color $duration $timing;

    &:hover,
    &:active,
    &:focus {
        color: $color-link--hover;
    }
}

.wysiwyg {
    a {
        color: $color-link;
    }
}

//=================================================================================================
// Highlight
//=================================================================================================
mark {
    background: $color-highlight;
    padding: 0 0.2rem;
}

//=================================================================================================
// Blockquote
//=================================================================================================
blockquote {
    @include font-size(24);
    padding: 0 1.5rem;
    border-left: $borders--blockquote;
    margin: 0;

    cite {
        display: block;
        font-size: $font-size--text;
        text-align: right;
        margin-top: 0;
    }
}

//=================================================================================================
// Code block styling
//=================================================================================================
pre {
    font-family: $code-family;
    font-size: $code-size;
    color: $code-color;
    background: $code-bg;
    tab-size: 2;
    padding: 1rem;
    border: 0;
    border-radius: $border-radius;
    margin: 0;

    code {
        font-family: $code-family;
        line-height: 1.2;
    }
}

//=================================================================================================
// Keyboard input
//=================================================================================================
kbd {
    display: inline-block;
    line-height: 1.4;
    font-family: "Helvetica", Arial, sans-serif;
    font-size: $font-size--text-xsmall;
    color: #333;
    text-shadow: 0 1px 0 #fff;
    background-color: #f7f7f7;
    padding: 0.1em 0.6em;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow:
        0 1px 0 rgba(0, 0, 0, 0.2),
        0 0 0 2px #fff inset;
    margin: 0 0.1em;
}

//=================================================================================================
// Inline code styling
//=================================================================================================
:not(pre) > code {
    font-family: $code-family;
    font-size: $code-size;
    color: $code-color;
    background: $code-bg;
    padding: 0 0.2rem;
    border: $borders;
    border-radius: $border-radius;
}

//=================================================================================================
// Line break
//=================================================================================================
hr {
    height: 0;
    border: 0;
    border-top: $borders;
}

//=================================================================================================
// Definition list
//=================================================================================================
dt {
    font-weight: $font-weight-semibold;
}

dd {
    margin-bottom: 0.5rem;
}

//=================================================================================================
// Image/video wrapper
//=================================================================================================
img {
    max-width: 100%;
    height: auto;
}

.image-wrapper {
    min-height: 100%;
    position: relative;
    @include flex($justifyContent: center, $alignItems: center);
    background-color: $color-bg--image;
    overflow: hidden;
}

.image-wrapper {
    video {
        margin-bottom: 10px;
    }
}

//=================================================================================================
// Gutter - Placement
//=================================================================================================
.no-gutter {
    padding-right: 0;
    padding-left: 0;
}

.content-right {
    text-align: right;
}

.container-center {
    @include flex($justifyContent: center, $alignItems: center);
}

//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {
    h1 {
        @include font-size(38);
    }

    h2 {
        @include font-size(28);
    }

    h3 {
        @include font-size(24);
    }

    h4 {
        @include font-size(19);
    }
}
