body.error404 {
    .cover {
        padding-top: 70px;

        &__container {
            &::before {
                top: -20px;
            }
        }
    }
}

.bloc-content--404 {
    h2 {
        // margin: 0;
        border: 0;
        @include font-size(200);
        text-align: center;

        &::before {
            display: none;
        }
    }

    p {
        font-size: $font-size--5;
        text-align: center;
    }
}

.search--404 {
    width: 970px;

    .searchResult {
        border-top: 1px solid #f1f7eb;
        border-right: 1px solid $color-main;
        border-left: 1px solid $color-main;
        border-bottom: 1px solid $color-main;
        border-radius: 0 0 $border-radius $border-radius;
        overflow: hidden;
    }

    .search-container {
        position: relative;
        @include flex($alignItems: center);

        &.search-container-open {
            input {
                border-radius: $border-radius $border-radius 0 0;
                border-color: #b8d79b !important;
                border-bottom: 0px;
            }
        }

        .searchInput {
            border-color: $search-input-border-color--404 !important;

            &:focus {
                border-color: $search-input-border-color--active !important;
            }
        }
    }
}

.grid-center-noGutter.container__buttons {
    margin: 30px 0;
}

//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {
    .bloc-content--404 {
        h2 {
            @include font-size(160);
        }
    }
}
