body.search {
    .cover {
        padding-top: 70px;

        &__container {
            &::before {
                top: -20px;
            }
        }
    }

    .searchInput {
        border: 1px solid $color-dark;
    }
}

.search-container {
    display: flex;
    position: relative;
    align-items: center;

    #searchInput {
        height: $search-input-height;
        padding: 24px 70px 24px 24px;
        background-color: $search-input-bg;
        border: 1px solid $color-dark;
        box-sizing: border-box;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }

        &:focus {
            border-color: $search-input-border-color--active;
        }
    }

    .search-svg {
        @include flex($alignItems: center, $justifyContent: center);
        @include absolute($top: 50%, $right: 25px);
        transform: translateY(-50%);
        @include size(auto);
        background-color: $search-input-button-bg;
        border: $search-input-button-border;
        border-radius: $search-input-button-radius;
        cursor: pointer;
        transition: {
            property: background-color, border-color;
            duration: $duration;
            timing-function: $timing;
        }

        svg {
            @include size($search-input-button-size);
            fill: $search-input-button-color;
            transition: fill $duration $timing;
        }

        &:hover,
        &:focus {
            background-color: $search-input-button-bg--active;
            border-color: $search-input-button-border-color--active;

            svg {
                fill: $search-input-button-color--active;
            }
        }
    }
}

.searchResult {
    position: relative;
    top: -2px;
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    border-top: 1px solid #f1f7eb;
    border-radius: 0 0 $border-radius $border-radius;

    li {
        position: relative;
        background-color: $color-white;
        border-bottom: 1px solid $color-bg--neutral;

        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 15px 24px;
            transition: {
                property: color, background-color, padding-left;
                duration: $duration;
                timing-function: $timing;
            }
        }

        .resultCPT {
            position: absolute;
            right: 25px;
            top: 50%;
            pointer-events: none;
            color: $color-gray;
            transform: translate(0, -50%);
            transition: color $duration $timing;
        }

        &.no-results {
            span {
                display: flex;
                width: 100%;
                height: 100%;
                padding: 15px 24px;
                color: $color-gray;
            }
        }

        &:not(.no-results) {
            a:hover,
            a:focus {
                padding-left: 35px;
                background-color: $color-main;
                color: $color-dark;

                &::after {
                    color: $color-dark;
                }
            }

            &.selected,
            &:hover,
            &:focus {
                .resultCPT {
                    color: $color-dark;
                }
            }
        }

        &:last-child {
            border-bottom: 0;
        }

        &.selected {
            a {
                padding-left: 35px;
                background-color: $color-main;
                color: $color-dark;

                &::after {
                    color: $color-dark;
                }
            }
        }

        &.show-all a {
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;

            &::after {
                content: "\f344";
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 10px;
                font: normal 1.125rem/1 dashicons;
                color: $color-text;
                transition: color $duration $timing;
            }
        }
    }
}

body.search-open {
    overflow: hidden;
}

body.search {
    .breadcrumb {
        display: none;
    }
    .container--search {
        padding-top: 30px;
    }
}

.search-popup {
    position: fixed;
    top: 0;
    z-index: 15;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: $color-bg--neutral;
    overflow: hidden;

    .container {
        position: relative;
        height: 100%;

        &::before {
            content: "";
            position: absolute;
            top: 276px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 0;
            width: 2100px;
            height: 223px;
            background-image: url($urlSpriteImage + "/theme-icons/CSS/ligne_recherche.svg");
            background-size: cover;
            background-repeat: no-repeat;
        }

        #searchInput:not(:focus) {
            border-color: transparent;
        }

        .search-popup__content {
            position: relative;
            top: 32%;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            width: 100%;
            height: 100%;
        }

        label {
            position: relative;
            display: flex;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto 13px auto;
            padding-bottom: 0;
            border: 0;
            font-family: $font-family--heading;
            font-size: $font-size--1;
            font-weight: $font-weight--heading;
            color: $color-text;
            text-transform: none;
        }

        .search-container {
            display: flex;
            position: relative;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto;

            &.search-container-open {
                border-radius: $border-radius $border-radius 0 0;

                & ~ .searchResult {
                    border-right: 1px solid #b8d79b;
                    border-bottom: 1px solid #b8d79b;
                    border-left: 1px solid #b8d79b;
                    border-radius: 0 0 $border-radius $border-radius;
                    overflow: hidden;
                }

                input {
                    border-radius: $border-radius $border-radius 0 0;
                    border-right: 1px solid #b8d79b !important;
                    border-top: 1px solid #b8d79b !important;
                    border-left: 1px solid #b8d79b !important;
                }
            }
        }

        .searchResult {
            width: calc(100% - 200px);
            left: 100px;
        }
    }

    .search-close {
        @include flex($alignItems: center, $justifyContent: center);
        cursor: pointer;
        @include absolute($top: 5%, $right: 15px);
        @include size(40px);
        margin: 0;
        padding: 0;
        background-color: $color-main;
        border-color: $color-main;
        border-radius: $border-radius--round;
        transition: {
            property: background-color, border-color;
            duration: $duration;
            timing-function: $timing;
        }

        svg {
            @include size(40px);
            fill: $color-dark;
            transition: fill $duration $timing;
        }

        &:hover,
        &:focus {
            background-color: transparent;
            border-color: $color-main;

            svg {
                fill: $color-dark;
            }
        }
    }
}

// //======================================================================================================
// // Search Page
// //======================================================================================================
.filter {
    .search-container {
        .searchInput:focus {
            border-color: $search-input-border-color--active !important;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .search-popup .container .search-container,
    .search-popup .container label {
        width: calc(100% - 140px);
    }
    .search-popup .container .searchResult {
        left: 70px;
        width: calc(100% - 140px);
    }
}

// 960
@media screen and (max-width: $medium) {
    .search-popup .container .search-container,
    .search-popup .container label {
        width: 100%;
    }
    .search-popup .container .searchResult {
        left: 0;
        width: 100%;
    }

    .search-popup .container .search-popup__content {
        top: 20%;
    }
}

// 640
@media screen and (max-width: $small) {
    .searchResult li .resultCPT {
        display: none;
    }
    .search-popup .container .search-popup__content {
        top: 15%;
    }

    .search-popup .container label {
        @include font-size(40);
    }
}
