//======================================================================================================
// General
//======================================================================================================
$theme_name: "saint-jean-de-folleville";

//======================================================================================================
// Breakpoints
//======================================================================================================
$large: 1200px;
$medium: 960px;
$small: 640px;
$x-small: 360px;

//======================================================================================================
// Grids
//======================================================================================================
$gl-colCount: 12;
$gl-gridName: grid;
$gl-colName: col;
$gl-attributeName: class;
$gl-gutter: 30px;
$gl-gutter-vertical: 30px;

//Gutter responsive medium
$gl-gutter-md: 20px;
$gl-gutter-md-vertical: 20px;

//Gutter responsive small
$gl-gutter-sm: 20px;
$gl-gutter-sm-vertical: 20px;

//Gutter responsive x-small
$gl-gutter-xs: 20px;
$gl-gutter-xs-vertical: 20px;

$gl-mq-width: "max-width";
$gl-mq-list: (
        lg: $large,
        md: $medium,
        sm: $small,
        xs: $x-small
);

//======================================================================================================
// Global Colors
//======================================================================================================

// Colors
$red: #c92c2c;
$redlight: #ffe6e6;
$orange: #e8810b;
$orangelight: #fae6ce;
$green: #46b450;
$greenlight: #e2ffef;

// Main Colors
$color-main: #b8d79b;
$color-second: #a5d7f5;
$color-third: #ffe500;
$color-fourth: #dedede;

// Black & White
$color-white: #ffffff;
$color-light: #f1f7eb;
$color-gray: #586672;
$color-dark: #1f1f1f;
$color-black: #000000;

// light colors
$color-green-light: #f1f7eb;
$color-blue-light: #edf7fd;
$color-yellow-light: #fffacc;

$color-card-notimg--home: $color-gray;

// RGBA
$color-main--rgba: rgba($color-main, 0.8);
$color-second--rgba: rgba($color-second, 0.8);
$color-third--rgba: rgba($color-third, 0.8);
$color-black--rgba: rgba($color-black, 0.9);
$color-overlay--rgba: rgba($color-black, 0.8); // on overlay accessconfig
$color-dark--rgba5: rgba($color-dark, 0.5);

// Bloc infos
$color-error: $red;
$color-alert: $orange;
$color-success: $green;
$color-bg-error: $redlight;
$color-bg-alert: $orangelight;
$color-bg-success: $greenlight;
$color-flexible-alert: #e20000; // This color is only used on bloc-content--alert

// Text
$color-text: $color-dark;
$color-text--light: $color-gray;
$color-link: $color-dark;
$color-link--hover: darken($color-link, 15%);
$color-highlight: #ffeea8;

// Background
$color-bg--neutral: $color-light;
$color-bg--body: $color-white;
$color-bg--transparent: transparent;
$color-bg--image: $color-gray;
$color-bg--icons: $color-white;

// SVG
$color-svg: $color-text--light;
$color-svg--events: $color-svg; // single events
$color-svg--maps: $color-svg; // popup maps

// Single Date publication (post & jobs & adverts)
$color-date--single: $color-text--light;

//======================================================================================================
// Typography
//======================================================================================================

// Font Weight
$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

// Body
$font-size: 16px;
$font-style: normal;
$font-variant: normal;
$font-family: "Nunito", sans-serif;
$font-family--second: "Oooh Baby", sans-serif;
$font-weight: $font-weight-normal;
$line-height: 1.5;

// Font Size
$font-size--text-medium: toRem(20); // 20px
$font-size--text: toRem(16); // 16px
$font-size--text-small: toRem(14); // 14px
$font-size--text-xsmall: toRem(13); // 13px

// Headings
$font-size--1: toRem(50); // 50px
$font-size--2: toRem(34); // 34px
$font-size--3: toRem(28); // 28px
$font-size--4: toRem(22); // 22px
$font-size--5: toRem(18); // 18px
$font-size--6: toRem(16); // 16px

$font-family--heading: $font-family;
$font-weight--heading: $font-weight-bold;
$line-height--heading: 1.2;

//======================================================================================================
// Borders
//======================================================================================================
$border-width: 1px;
$border-width--blockquote: 16px;
$border-style: solid;
$border-color: $color-fourth;
$border-radius: 20px;
$br15: 15px;
$br10: 10px;
$br5: 5px;
$border-radius--input: 4px;
$border-radius--round: 50%;
$border-radius--image: 0; // For all images and sliders
$borders: $border-width $border-style $border-color;
$borders--blockquote: $border-width--blockquote $border-style $border-color;

//======================================================================================================
// Box-shadow
//======================================================================================================
$shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
$shadow-second: 1px 1px 20px 0px rgba(40, 40, 40, 0.1);

//======================================================================================================
// Transitions
//======================================================================================================
$duration: 0.35s;
$duration--second: 0.5s;
$timing: ease-in-out;
// if need
// $delay                                  : .5s;

//======================================================================================================
// Image URL
//======================================================================================================
// Need for no-image icon plugins :
$urlSpriteImage: "/wp-content/themes/#{$theme_name}/assets/src/images/sprite-images/";
// Need for no-image icon socle + post-navigation :
$urlShort: "/wp-content/themes/#{$theme_name}/assets/src/images/sprite-images/base-icons/";
$urlShortImage: "/wp-content/themes/#{$theme_name}/assets/src/images/";

// If need
// $urlShortTheme                          : '/wp-content/themes/#{$theme_name}/assets/src/images/sprite-images/theme-icons/';

//======================================================================================================
// Buttons
//======================================================================================================

// Height
$btn-height: 50px; // 640 : unset

// Font
$btn-font-family: $font-family;
$btn-font-weight: $font-weight-bold;
$btn-font-size: toRem(15);
$btn-line-height: 1.2;
$btn-text-transform: none;

// Colors
$btn-color: $color-dark;
$btn-color--hover: $color-dark;

// Backgrounds
$btn-bg: $color-second;
$btn-bg--hover: $color-bg--transparent;

// Borders
$btn-border-width: 1px;
$btn-border-style: solid;
$btn-border-radius: 30px;
$btn-border-color: $btn-bg;
$btn-border-color--hover: darken($btn-bg, 10%);

// Padding
$btn-padding: 15px 25px;

//===============================================
// Button secondary (used on document flexible)
//===============================================

// Colors
$btn-second-color: $color-dark;
$btn-second-color--hover: $color-dark;

// Backgrounds
$btn-second-bg: $color-green-light;
$btn-second-bg--hover: transparent;

// Borders
$btn-second-border-color: $btn-second-bg;
$btn-second-border-color--hover: $btn-second-bg;

// Padding
$btn-second-padding: $btn-padding;

//===============================================
// Button variant 1 (if you need)
//===============================================

// Colors
$btn-variant-color: $color-main;
$btn-variant-color--hover: $color-white;

// Backgrounds
$btn-variant-bg: $color-bg--neutral;
$btn-variant-bg--hover: $color-bg--transparent;

// Borders
$btn-variant-border-color: $btn-variant-bg;
$btn-variant-border-color--hover: $color-white;

//======================================================================================================
// Buttons specific (tag & empty)
//======================================================================================================

// Font
$btn-spe-font-weight: $font-weight;
$btn-spe-font-size: $font-size--text-xsmall;
$btn-spe-line-height: 1.5;

// Padding
$btn-spe-padding: 7px 15px;

// Borders
$btn-spe-border-radius: 50px;

//===============================================
// Button empty (button for filter or reset)
//===============================================

// Colors
$btn-empty-color: $color-dark;
$btn-empty-color--hover: $color-white;

// Backgrounds
$btn-empty-bg: $color-bg--transparent;
$btn-empty-bg--hover: $color-dark;

// Borders
$btn-empty-border-color: $color-dark;
$btn-empty-border-color--hover: $btn-empty-bg--hover;

//===============================================
// Button tag (button bottom page (transition or tag actu))
//===============================================

// Colors
$btn-tag-color: $color-dark;
$btn-tag-color--hover: $color-dark;

// Backgrounds
$btn-tag-bg: $color-main;
$btn-tag-bg--hover: $color-white;

// Borders
$btn-tag-border-color: $btn-tag-bg;
$btn-tag-border-color--hover: $btn-tag-bg;

//===============================================
// Button Privacy Policy
//===============================================

// Colors
$btn-privacy-color: $color-text;
$btn-privacy-color--hover: $color-white;

// Backgrounds
$btn-privacy-bg: $color-white;
$btn-privacy-bg--hover: $color-bg--transparent;

// Borders
$btn-privacy-border-color: $btn-privacy-bg;
$btn-privacy-border-color--hover: $btn-privacy-bg;

//===============================================
// Button document-details button (and contact details)
//===============================================

$btn-action-size: 30px; // Width & height

// Colors
$btn-action-color: $color-text;
$btn-action-color--hover: $color-dark;

// Backgrounds
$btn-action-bg: transparent;
$btn-action-bg--hover: $btn-action-bg;

// Borders
$btn-action-width: 0;
$btn-action-style: $border-style;
$btn-action-border-color: $btn-action-bg;
$btn-action-border-color--hover: $btn-action-bg--hover;
$btn-action-border-radius: $border-radius--round;

//======================================================================================================
// Forms
//======================================================================================================
$forms: (
        "[type=color], [type=date], [type=datetime], [type=datetime-local], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=url], [type=week], [type=time], select, textarea"
);
$btn: (".button, button, [type=submit], [type=reset], [type=button]");
$input-bg: $color-bg--transparent;
$placeholder: darken($color-main, 20%);
$form-border-color: $color-gray;
$form-border-color--focus: $color-dark;
$form-border: $border-width $border-style $form-border-color;
$form-color-icon: $color-text--light; // Couleur des icones dans les formulaires proposition events

//======================================================================================================
// Search input (for popup, search page and 404)
//======================================================================================================

// Input
$search-input-height: 68px;
$search-input-bg: $color-white;
$search-input-border-color: $color-white;
// 404 border color
$search-input-border-color--404: $color-gray;
// Active / focus
$search-input-border-color--active: $color-main;

// Button search loop
$search-input-button-size: 27px;
$search-input-button-border: 1px $border-style $color-main;
$search-input-button-radius: $border-radius--round;
$search-input-button-bg: $color-main;
$search-input-button-color: $color-dark;

// Active / focus
$search-input-button-bg--active: $color-bg--transparent;
$search-input-button-color--active: $color-dark;
$search-input-button-border-color--active: $color-main;

//======================================================================================================
// Tables
//======================================================================================================
$stripes: #f8f8f8;
$caption: #ababab;

// Flexible table
$table-bg: $color-white;
$table-color: $color-text;
$table-border-width: 1px;
$table-border-color: $color-dark;
$table-even-bg: $color-white; // Pair background-color
$table-even-color: $color-text; // Pair color
$table-odd-bg: $color-light; // Impair background-color
$table-odd-color: $color-text; // Impair color
$table-head-bg: $color-dark;
$table-head-color: $color-white;
$table-head-border-color: $color-white;

//======================================================================================================
// Code
//======================================================================================================
$code-color: $color-text;
$code-size: $font-size--text-small;
$code-family: Menlo, monospace;
$code-bg: $color-bg--transparent;

//======================================================================================================
// Card
//======================================================================================================
// Padding général des cards
$card--padding: 20px;

// Si le padding général est égal à 0
$card-content--padding: 20px; // 20px si le padding général est égal à 0
$card-categorie--margin: $card--padding;
$card-event-v1-gap: 0; // 0 si le padding général est égal à 0

// Height de l'image des cards
$card-imageH: 200px; // 200px si le padding général est égal à 0
$card-imageH-Chronology-RWD: 160px; // 160px si le padding général est égal à 0

// style Card
$card--bg: $color-white;
$card--bg--neutral: $color-bg--neutral; // For flexibles portrait & chronology
$card-date-v1--bg: $color-main;
$card-date-v2--bg: $color-main--rgba;

$card--shadow: 1px 1px 20px 0px #2828281a;
$card--border: 0;
$card--radius: $border-radius;

$card--color: $color-text;
$card--color--second: $color-text--light; // Date publication & location card
$card-date--color: $color-dark;

// Hover & Focus Card
$card--bg--hover: $color-blue-light;
$card--shadow--hover: none;
$card--bd-color--hover: transparent;

//======================================================================================================
// Taxo (card + document-details + reports)
//======================================================================================================
// Font
$taxo-font-family: $font-family;
$taxo-font-size: toRem(14);
$taxo-font-weight: $font-weight-bold;
$taxo-line-height: toRem(20);
$taxo-text-transform: uppercase;

// Color
$taxo-color: $color-text;

// Background
$taxo-bg: $color-second;

// Borders
$taxo-border-width: 0;
$taxo-border-style: $border-style;
$taxo-border-color: $taxo-bg;
$taxo-border-radius: 20px;

// Padding
$taxo-padding: 5px 15px;

// Hover & Focus card
$taxo-bg--hover: $color-white;
$taxo-border-color--hover: $color-dark;
$taxo-color--hover: $taxo-color;

//======================================================================================================
// Image Caption
//======================================================================================================

$border-radius-caption: 6px;

// Background Caption
$color-bg-caption: $color-main--rgba;

// Text Caption
$color-caption: $color-dark;

//======================================================================================================
// Flexibles - Lists
//======================================================================================================
$bullet-level-1: $color-main;
$bullet-level-2: $color-second;
$bullet-level-3: $color-third;

//======================================================================================================
// Flexibles - bloc content background
//======================================================================================================

// Fexible bloc Primary
$b-primary-bg: $color-main; // For flexible bloc content border & background
$b-primary-color: $color-dark;

// Fexible bloc Secondary
$b-secondary-bg: $color-third; // For flexible bloc content border & background
$b-secondary-color: $color-dark;

// Fexible bloc Neutral
$b-neutral-bg: $color-second; // For flexible bloc content border & background
$b-neutral-color: $color-dark;

//======================================================================================================
// Flexibles - Albums - Swiper + Glightbox navigation
//======================================================================================================

// Arrow
$arrow-size: 40px; // width + height
$arrow-size-svg: 30px;
$arrow-bg: $color-main;
$arrow-border: 1px solid $color-main;
$arrow-border-radius: $border-radius--round;
$arrow-color: $color-dark;
$arrow-bg--hover: transparent;
$arrow-bd--hover: $color-main;
$arrow-color--hover: $color-dark;

// Cross on Glightbox
$cross-size: 40px; // width + height
$cross-size-svg: 30px;
$cross-bg: $arrow-bg;
$cross-border: $arrow-border;
$cross-border-radius: $arrow-border-radius;
$cross-color: $arrow-color;
$cross-bg--hover: $arrow-bg--hover;
$cross-bd--hover: $arrow-bd--hover;
$cross-color--hover: $color-white;

//======================================================================================================
// Flexibles - Tabs (for flexibles, comarquage, acf form)
//======================================================================================================
$tabs-bg: $color-light;
$tabs-border: $border-width $border-style;
$tabs-border-color: transparent;
$tabs-color: $color-dark;
$tabs-bg--active: $color-main;
$tabs-border-color--active: $color-main;
$tabs-color--active: $color-dark;

$tabs-panel-bg: $color-white;
$tabs-panel-border: $border-width $border-style $color-main;

//======================================================================================================
// Flexibles - Accordions (for flexibles & comarquage)
//======================================================================================================
$accord-min-height: 70px;
$accord-min-padding: 20px 70px 20px 30px;
$accord-ffamily: $font-family--heading;
$accord-fweight: $font-weight-bold;
$accord-fsize: $font-size--4;
$accord-line-height: $line-height--heading;
$accord-color: $color-dark;
$accord-bg: $color-main;
$accord-svg-fill: $accord-color;
$accord-details-border: $border-width $border-style $color-main;

//======================================================================================================
// Accessibility a42
//======================================================================================================
$a42-background: $color-white;
$a42-border: 0;
$a42-border-radius: 0;

$a42_title-ffamily: $font-family--heading;
$a42_title-fweight: $font-weight--heading;
$a42_title-fsize: toRem(28);
$a42_title-lineheight: $line-height--heading;
$a42_title-color: $color-text;

$a42-card-background: $color-bg--neutral;
$a42-card-border: $color-main;
$a42-card-border-width: 0 0 0 0.625em;
$a42-card-border-radius: 0;
$a42-card-color: $color-text;
$a42-card-input-background: $color-white;
$a42-card-input-border: $color-main;
$a42-card-input-active: $color-main;

$a42-cross-thickness: 1px;
$a42-cross-bg: $btn-bg;
$a42-cross-bd-color: $btn-border-color;
$a42-cross-color: $btn-color;
$a42-cross-bg--hover: $btn-bg--hover;
$a42-cross-bd-color--hover: $btn-border-color--hover;
$a42-cross-color--hover: $btn-color--hover;

$color-overlay--rgba: rgba($color-black, 0.8); // on overlay accessconfig

//======================================================================================================
// Filters
//======================================================================================================

// Filter bloc
$filter-bg: $color-bg--neutral;

//===============================================
// Button toggler
//===============================================

// Title
$btn-toggler-title-font-family: $font-family--heading;
$btn-toggler-title-font-size: $font-size--text-medium;
$btn-toggler-title-font-weight: $font-weight-normal;
$btn-toggler-title-line-height: $line-height--heading;
$btn-toggler-title-text-transform: uppercase;
$btn-toggler-title-color: $color-text;

// Icon - Colors
$btn-toggler-color: $color-white;
$btn-toggler-color--hover: $color-dark;

// Icon - Backgrounds
$btn-toggler-bg: $color-dark;
$btn-toggler-bg--hover: $color-bg--transparent;

// Icon - Borders
$btn-toggler-border-color: $btn-toggler-bg;
$btn-toggler-border-color--hover: $btn-toggler-border-color;

//===============================================
// Filter input
//===============================================
$f-input-font-size: $font-size--text-small;
$f-input-color: $color-text;
$f-input-bg: $color-bg--transparent;
$f-input-border-width: 1px;
$f-input-border-radius: $br5;
$f-input-border-color: $color-gray;
$f-input-icon: $color-gray;
// Container subcategory
$f-input-container-bg: $color-white;

// Active filter
$f-input-color--active: $color-dark;
$f-input-bg--active: $color-bg--transparent;
$f-input-border-color--active: $color-main;
$f-input-icon--active: $color-dark;

// Selected filter
$f-input-color--selected: $color-dark;
$f-input-bg--selected: $color-green-light;
$f-input-border-color--selected: $color-main;
$f-input-icon--selected: $color-dark;

//===============================================
// Sub Filter input
//===============================================
$f-sub-input-color: $color-text;
$f-sub-input-bg: $color-white;
$f-sub-input-border-width: 1px;
$f-sub-input-border-radius: $br5;
$f-sub-input-border-color: $color-gray;

// Sub filter checked
$f-sub-input-color--checked: $color-dark;
$f-sub-input-bg--checked: $color-main;
$f-sub-input-border-color--checked: $color-gray;

//===============================================
// Filter input Keyword + Button Close
//===============================================
// Input
$f-inputKeyword-border-color: $f-input-border-color;

// Active filter
$f-inputKeyword-border-color--active: $color-main;

// Selected filter
$f-inputKeyword-border-color--selected: $color-main;

// Button Close
//===============================================
$f-Keyword-close-border-width: 1px;

// Colors
$f-Keyword-color: $color-white;
$f-Keyword-color--hover: $color-dark;

// Backgrounds
$f-Keyword-bg: $color-dark;
$f-Keyword-bg--hover: $color-bg--transparent;

// Borders
$f-Keyword-border-color: $f-Keyword-bg;
$f-Keyword-border-color--hover: $f-Keyword-bg;
