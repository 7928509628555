.breadcrumb {
    .container {
        padding: 0;
    }
    ul {
        display: block;
        min-height: 60px;
        padding: 18px 0;
    }

    li {
        position: relative;
        display: inline;
        color: $color-text--light;
        line-height: 1.75;

        a {
            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        svg {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            fill: $color-text--light;
            vertical-align: text-top;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {
    .breadcrumb {
        li svg {
            margin: 0;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .breadcrumb {
        li svg {
            margin: 0 -1px;
        }
    }
}
