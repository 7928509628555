.front-page {
    &__title {
        margin: 0 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: $font-family;
        font-size: toRem(50);
        font-weight: $font-weight-semibold;
        line-height: toRem(50);
        color: $color-dark;
        width: fit-content;
        text-transform: uppercase;

        span {
            font-family: $font-family--second;
            font-size: toRem(44);
            font-weight: $font-weight-normal;
            line-height: toRem(45);
            color: $color-dark;
            text-transform: none;
        }

        &::before {
            top: unset;
            bottom: 21px;
            width: 43px;
            left: -15px;
            height: 46px;
        }
    }
    // ==================================
    // Slideshow
    // ==================================

    .slideshow {
        margin-top: -130px;
        height: 610px;
        position: relative;
        z-index: 0;

        &::before {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 80%);
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }

        &--image {
            height: 100%;
        }

        &--video {
            img {
                display: none;
            }
        }

        &__container {
            height: 100%;

            img,
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .container--pagination {
            position: relative;
        }

        .c-pagination {
            @include absolute($bottom: 20px, $right: 15px);
            @include flex($alignItems: center, $justifyContent: flex-end);
            gap: 10px;
            height: 25px;
            padding: 6px 15px;
            background-color: rgba($color-dark, 0.6);
            border-radius: $btn-spe-border-radius;
            z-index: 2;
            pointer-events: auto;

            .nav-swiper-autoplay {
                @include size(12px);
                padding: 0;
                background-color: transparent;
                border: 0;
                border-radius: 0;
                margin: 0;

                &__icon {
                    @include size(12px);
                    fill: $color-white;
                }

                &:hover,
                &:focus {
                    .nav-swiper-autoplay__icon {
                        fill: $color-white;
                    }
                }
            }

            &__bullets {
                @include flex($alignItems: center);
                gap: 10px;

                .swiper-pagination-bullet {
                    @include size(12px);
                    margin: 0 !important;
                    opacity: 1 !important;
                    background-color: $color-white;
                    border: 1px solid $color-white;
                    border-radius: $border-radius--round;
                    transition: {
                        property: background-color, border-color;
                        duration: $duration;
                        timing-function: $timing;
                    }

                    &:hover,
                    &:focus,
                    &-active {
                        background-color: transparent;
                        border-color: $color-white;
                    }
                }
            }

            .hidden {
                display: none;
            }
        }
    }

    // ==================================
    // Access
    // ==================================
    .access {
        margin-top: -90px;

        &__container {
            display: flex;
            align-items: flex-end;
            gap: 76.5px;
        }

        &__big-item {
            margin-left: 60px;
            width: 161px;
            position: relative;
            z-index: 5;
        }

        &__big-link {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
            width: 150px;

            &:hover,
            &:focus {
                .access__big-icon-container {
                    &::before {
                        transform: rotate(45deg);
                    }

                    &::after {
                        transform: scale(1.1);
                    }
                }
            }

            p {
                font-family: $font-family;
                font-size: toRem(24);
                font-weight: $font-weight-bold;
                line-height: toRem(45);
                color: $color-dark;
                display: flex;
                flex-direction: column;
                align-items: center;

                span {
                    font-family: $font-family--second;
                    font-size: toRem(29);
                    font-weight: $font-weight-normal;
                    line-height: 1.2;
                    color: $color-dark;
                    margin-top: -21px;
                }
            }
        }

        &__big-icon-container {
            width: 144px;
            height: 144px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-white;
            border-radius: $border-radius--round;
            position: relative;
            box-shadow: 1px 1px 20px rgba(40, 40, 40, 0.1);

            svg {
                height: 60px;
                width: 60px;
            }

            &::before {
                content: "";
                position: absolute;
                top: -1px;
                left: -3px;
                z-index: 1;
                width: 140px;
                height: 135px;
                background-image: url($urlSpriteImage + "/theme-icons/cercle_grand_ar.svg");
                background-repeat: no-repeat;
                background-size: cover;
                transition: transform $duration $timing;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 1px;
                right: -15px;
                z-index: -1;
                width: 70px;
                height: 65px;
                background-image: url($urlSpriteImage + "/theme-icons/bulle_grand_ar.svg");
                background-repeat: no-repeat;
                background-size: cover;
                transition: transform $duration $timing;
            }
        }

        &__item-list {
            display: flex;
            align-items: center;
            gap: 50px;
            margin-bottom: 25px;
        }

        &__icon-container {
            width: 110px;
            height: 110px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-white;
            border-radius: $border-radius--round;
            position: relative;
            box-shadow: 1px 1px 20px rgba(40, 40, 40, 0.1);

            svg {
                height: 60px;
                width: 60px;
            }

            &::before {
                content: "";
                position: absolute;
                top: 2px;
                left: 1px;
                z-index: 1;
                width: 110px;
                height: 105px;
                mask-image: url($urlSpriteImage + "/theme-icons/cercle_ar.svg");
                mask-repeat: no-repeat;
                mask-size: cover;
                background-color: $color-second !important;
                transition: transform $duration $timing;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0px;
                right: -8px;
                z-index: -1;
                width: 50px;
                height: 50px;
                mask-image: url($urlSpriteImage + "/theme-icons/bulle_ar.svg");
                mask-repeat: no-repeat;
                mask-size: cover;
                background-color: $color-second !important;
                transition: transform $duration $timing;
            }
        }

        &__item-link {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 13px;
            width: 130px;

            &:hover,
            &:focus {
                .access__icon-container {
                    &::before {
                        transform: rotate(45deg);
                    }

                    &::after {
                        transform: scale(1.1);
                    }
                }
            }

            p {
                font-family: $font-family;
                font-size: toRem(16);
                font-weight: $font-weight-bold;
                line-height: 1.2;
                color: $color-dark;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &:nth-child(odd) {
                .access__icon-container {
                    &::before {
                        content: "";
                        position: absolute;
                        top: 2px;
                        left: 1px;
                        z-index: 1;
                        width: 110px;
                        height: 105px;
                        mask-image: none;
                        background-image: url($urlSpriteImage + "/theme-icons/cercle_ar.svg");
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-color: transparent !important;
                        transition: transform $duration $timing;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 0px;
                        right: -8px;
                        z-index: -1;
                        width: 50px;
                        height: 50px;
                        mask-image: none;
                        background-image: url($urlSpriteImage + "/theme-icons/bulle_ar.svg");
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-color: transparent !important;
                        transition: transform $duration $timing;
                    }
                }
            }
        }
    }

    // ==================================
    // News
    // ==================================

    .news {
        margin-top: 64px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 290px;
            left: 0px;
            z-index: -1;
            width: 100vw;
            height: 566px;
            background-color: rgba($color-second, 0.2);
        }

        &::after {
            content: "";
            position: absolute;
            top: 595px;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
            width: 2100px;
            height: 226px;
            background-image: url($urlSpriteImage + "/theme-icons/ligne_actus.svg");
            background-repeat: no-repeat;
            background-size: cover;
        }

        &__deco {
            position: absolute;
            left: 50%;
            transform: translateX(-531px - 85px);
            width: 85px;
            height: 85px;
            top: 450px;
            fill: $color-second;
        }

        &__container {
            overflow: hidden;
            padding-top: 50px;
        }

        &__title {
            align-items: flex-start;

            &::before {
                background-image: url($urlSpriteImage + "/theme-icons/bulle_titre_home.svg");
                top: unset;
                right: unset;
                bottom: 21px;
                left: -16px;
                width: 43px;
                height: 46px;
            }

            &--small {
                margin: 0 0 10px;
                font-family: $font-family;
                font-size: toRem(22);
                font-weight: $font-weight-bold;
                line-height: toRem(24);
                color: $color-dark;
            }

            span {
                margin-left: 71px;
            }
        }

        &__big-item {
            margin-top: -108px;
        }

        &__big-link {
            display: flex;
            align-items: flex-end;

            &:hover,
            &:focus {
                .news__big-content {
                    background-color: $color-second;
                    box-shadow: none;
                }

                .news__big-image {
                    img {
                        transform: scale(1.05);
                    }
                }
            }
        }

        &__big-content {
            width: calc(50% - 55px);
            margin-bottom: 30px;
            position: relative;
            background-color: $color-white;
            border-radius: $border-radius;
            box-shadow: 1px 1px 20px 0px rgba(40, 40, 40, 0.1);
            padding: 50px 40px;
            transition: {
                property: background-color, box-shadow;
                duration: $duration;
                timing-function: $timing;
            }
        }

        &__big-image {
            width: calc(50% + 85px);
            height: 410px;
            margin-left: -30px;
            overflow: hidden;
            border-radius: $border-radius;
            z-index: -1;

            img {
                border-radius: $border-radius;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform $duration $timing;
            }

            &.news__image--notimg {
                background: url($urlSpriteImage + "/base-icons/posts-icons/post.svg") no-repeat $color-card-notimg--home;
                background-position: 50%;
                border-radius: $border-radius;
            }
        }

        &__big-title {
            margin: 10px 0;
            font-family: $font-family;
            font-size: toRem(28);
            font-weight: $font-weight-bold;
            line-height: toRem(30);
            color: $color-dark;
        }

        &__big-introduction {
            font-family: $font-family;
            font-size: toRem(16);
            font-weight: $font-weight-normal;
            line-height: toRem(24);
            color: $color-dark;
        }

        &__content-container {
            position: relative;

            // &::before {
            //     content: "";
            //     position: absolute;
            //     // On le met à 5px car on a le padding-top de 35px
            //     top: 5px;
            //     left: -31px;
            //     z-index: 0;
            //     width: 85px;
            //     height: 80px;
            //     background-image: url($urlSpriteImage + "/theme-icons/bulle_actus.svg");
            //     background-repeat: no-repeat;
            //     background-size: contain;
            //     background-position: center;
            // }
        }

        &__introduction {
            font-family: $font-family;
            font-size: toRem(16);
            font-weight: $font-weight-normal;
            line-height: toRem(24);
            color: $color-dark;
        }

        &__swiper {
            padding: 35px 0;
            position: relative;
            overflow: visible !important;

            // &::before {
            //     content: "";
            //     position: absolute;
            //     height: 250px;
            //     left: -265px;
            //     top: 14px;
            //     width: 250px;
            //     background-color: #edf7fd;
            //     z-index: 10;
            // }

            // &::after {
            //     content: "";
            //     position: absolute;
            //     height: 250px;
            //     right: -265px;
            //     top: 14px;
            //     width: 250px;
            //     background-color: #edf7fd;
            //     z-index: 10;
            // }
        }

        &__item {
            width: 370px;
            height: auto;
            background-color: $color-white;
            box-shadow: 1px 1px 20px 0px rgba(40, 40, 40, 0.1);
            border-radius: $border-radius;
            padding: 45px 30px;
            box-sizing: border-box;
            transition: {
                property: background-color, box-shadow;
                duration: $duration;
                timing-function: $timing;
            }

            &:hover,
            &:focus {
                background-color: $color-second;
                box-shadow: none;
            }
        }

        &__buttons {
            display: flex;
            margin-top: 5px;
            justify-content: space-between;
        }

        &__button {
            position: relative;
            z-index: 0;
            height: 45px;
            margin: 0;
            font-family: $font-family;
            font-size: toRem(15);
            font-weight: $font-weight-bold;
            color: $color-dark;

            svg {
                transition: transform $duration $timing;
            }

            &:hover,
            &:focus {
                background-color: $color-second;
                svg {
                    transform: translateX(10px);
                }
            }
        }

        &__navigation {
            display: flex;
            gap: 10px;
        }

        .swiper-button-prev,
        .swiper-button-next {
            position: static;
            width: 40px;
            height: 40px;
            background-color: $color-second;
            padding: 0;
            border: 1px solid $color-second;
            border-radius: $border-radius--round;
            margin: 0;
            transition: all $duration ease-in-out;
            z-index: 0;

            &:hover,
            &:focus {
                background-color: $color-white;

                svg {
                    fill: $color-dark;
                }
            }

            & svg {
                width: 30px;
                height: 30px;
                fill: $color-dark;
            }

            svg {
                width: 30px;
                height: 30px;
            }

            &::after {
                display: none;
            }
        }
    }

    // ==================================
    // Admin-area-1
    // ==================================

    .admin-area-1 {
        margin-top: 154px;

        &.no-image {
            display: none;
        }

        &__container {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 95px;
                right: -121px;
                z-index: -1;
                width: 295px;
                height: 375px;
                background-image: url($urlSpriteImage + "/theme-icons/bulles_zoom.svg");
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        &__title {
            position: absolute;
            top: 8px;
            right: 217px;
            margin-right: -20px;

            &::before {
                mask-image: url($urlSpriteImage + "/theme-icons/bulle_titre_home.svg");
                mask-repeat: no-repeat;
                mask-size: cover;
                background-color: $color-third;
                background-image: none;
                width: 43px;
                height: 46px;
                bottom: 21px;
                left: -16px;
                top: unset;
            }

            span {
                margin-left: 20px;
            }
        }

        .bloc {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            &__wrapper {
                display: flex;
                align-items: flex-start;

                &:hover,
                &:focus {
                    .bloc__image {
                        img {
                            transform: scale(1.05);
                        }
                    }

                    .bloc__content {
                        box-shadow: none;
                        background-color: $color-yellow-light;
                    }
                }
            }

            &__title {
                font-family: $font-family;
                font-size: toRem(28);
                font-weight: $font-weight-bold;
                line-height: toRem(30);
                color: $color-dark;
                margin: 10px 0;
            }

            &__description {
                font-family: $font-family;
                font-size: toRem(16);
                font-weight: $font-weight-normal;
                line-height: toRem(24);
                color: $color-dark;
            }

            &__image {
                width: calc(50% + 85px);
                height: 410px;
                overflow: hidden;
                border-radius: $border-radius;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: transform $duration $timing;
                }
            }

            &__content {
                width: calc(50% - 55px);
                margin-top: 134px;
                border-radius: $border-radius;
                background-color: $color-white;
                position: relative;
                padding: 40px;
                margin-left: -30px;
                box-shadow: 1px 1px 20px 0px rgba(40, 40, 40, 0.1);
                transition: {
                    property: background-color, box-shadow;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &__link {
                display: flex;
                align-items: center;
                gap: 5px;
                height: 45px;
                padding: 0 25px;
                font-family: $font-family;
                font-size: toRem(15);
                font-weight: $font-weight-bold;
                line-height: 1.2;
                color: $color-dark;
                background-color: $color-third;
                border-radius: 50px;
                max-width: 181px;
                border: 1px solid $color-third;
                transition: background-color $duration $timing;
                position: relative;
                margin-top: 10px;
                &:hover,
                &:focus {
                    background-color: $color-white;
                }

                svg {
                    width: 26px;
                    height: 26px;
                }
            }
        }
    }

    // ==================================
    // Events
    // ==================================

    .events {
        margin-top: 31px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 270px;
            left: 0px;
            z-index: -2;
            width: 2000px;
            height: 440px;
            background-color: $color-green-light;
        }

        &.no-image {
            margin-top: 138px;
        }

        &::after {
            content: "";
            position: absolute;
            top: 436px;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
            width: 2100px;
            height: 208px;
            background-image: url($urlSpriteImage + "/theme-icons/ligne_agenda.svg");
            background-repeat: no-repeat;
            background-size: cover;
        }

        &__title {
            margin-bottom: 30px;
            &::before {
                top: unset;
                bottom: 21px;
                width: 43px;
                height: 46px;
                left: -15px;
            }

            &--small {
                margin-top: 20px;
                width: 100%;
                font-family: $font-family;
                font-size: toRem(28);
                font-weight: $font-weight-bold;
                line-height: toRem(30);
                color: $color-dark;
                margin-bottom: 0;
            }

            span {
                margin-left: -6px;
            }
        }

        &__link {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            position: relative;
            &:hover,
            &:focus {
                .events__date {
                    background-color: $color-white;
                }

                .events__image {
                    img {
                        transform: scale(1.05);
                    }
                }
            }
        }

        &__image {
            width: 560px;
            height: 350px;
            overflow: hidden;
            border-radius: $border-radius;
            img {
                width: 100%;
                height: 100%;
                border-radius: $border-radius;
                object-fit: cover;
                transition: transform $duration $timing;
            }

            &--notimg {
                background: url($urlSpriteImage + "/events-icons/events.svg") no-repeat $color-card-notimg--home;
                background-position: 50%;
                border-radius: $border-radius;
                // z-index: 15;
            }
        }

        &__date {
            border-radius: $br10;
            background-color: $color-main;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 25px;
            position: absolute;
            left: 0;
            top: 300px;
            transition: background-color $duration $timing;

            &__month {
                margin-left: 5px;
            }

            div {
                display: inline-flex;
            }

            p {
                font-family: $font-family;
                font-size: toRem(22);
                font-weight: $font-weight-extrabold;
                line-height: toRem(24);
                color: $color-dark;
                text-transform: uppercase;
            }

            svg {
                height: 20px;
                width: 20px;
            }
        }

        &__buttons {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 63px;
        }

        &__button {
            background-color: $color-main;
            border-radius: 50px;
            font-family: $font-family;
            font-size: toRem(15);
            font-weight: $font-weight-bold;
            line-height: 1.2;
            color: $color-dark;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 230px;
            padding: 9.5px 25px;

            &:hover,
            &:focus {
                svg {
                    transform: translateX(10px);
                }
            }

            svg {
                height: 26px;
                width: 26px;
                transition: transform $duration $timing;
            }
        }

        &__navigation {
            display: flex;
            gap: 10px;
        }

        .swiper-button-prev,
        .swiper-button-next {
            position: static;
            width: 40px;
            height: 40px;
            background-color: $color-main;
            padding: 0;
            border: 1px solid $color-main;
            border-radius: $border-radius--round;
            margin: 0;
            transition: all $duration ease-in-out;

            z-index: 0;

            &:hover,
            &:focus {
                background-color: $color-white;

                svg {
                    fill: $color-dark;
                }
            }

            & svg {
                width: 30px;
                height: 30px;
                fill: $color-dark;
            }

            svg {
                width: 30px;
                height: 30px;
            }

            &::after {
                display: none;
            }
        }
    }

    // ==================================
    // Kiosque + social group
    // ==================================

    .kiosque-social-group {
        margin-top: 147px;
        &__container {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
    }

    // ==================================
    // Kiosque
    // ==================================

    .kiosque {
        width: calc(50% - 91px);

        &__title {
            &::before {
                mask-image: url($urlSpriteImage + "/theme-icons/bulle_titre_home.svg");
                mask-repeat: no-repeat;
                mask-size: cover;
                background-color: $color-second;
                background-image: none;
                width: 43px;
                height: 46px;
                bottom: 21px;
                left: -16px;
                top: unset;
            }
        }

        &__image {
            width: 270px;
            height: 380px;
            box-shadow: 1px 1px 20px 0px rgba(40, 40, 40, 0.1);
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &--notimg {
                background: url($urlSpriteImage + "/base-icons/documents-icons/documents.svg") no-repeat
                    $color-card-notimg--home;
                background-position: 50%;
            }
        }

        .swiper-container {
            // overflow: visible;
            height: 410px;
            display: flex;
            align-items: center;
            padding-left: 15px;
            margin-left: -15px;
        }
        &__list {
            height: 380px;
        }

        &__item-title {
            width: 194px;
            font-family: $font-family;
            font-size: toRem(28);
            font-weight: $font-weight-bold;
            line-height: toRem(30);
            color: $color-dark;
            margin: 0;
        }

        &__extension {
            span {
                text-transform: uppercase;
            }
        }

        &__date {
            margin-top: 20px;
        }

        &__date,
        &__extension {
            font-family: $font-family;
            font-size: toRem(16);
            font-weight: $font-weight-normal;
            line-height: toRem(24);
            color: $color-gray;
            width: fit-content;
        }

        &__item {
            display: flex;
            gap: 30px;
        }

        &__slide-content {
            padding-top: 15px;
        }

        &__buttons {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 20px;
        }

        &__button {
            display: flex;
            align-items: center;
            gap: 10px;
            font-family: $font-family;
            font-size: toRem(15);
            font-weight: $font-weight-bold;
            line-height: 1.2;
            color: $color-dark;
            height: 30px;
            svg {
                width: 30px;
                height: 30px;
                background-color: $color-second;
                border: 1px solid $color-second;
                border-radius: $border-radius--round;
                transition: background-color $duration $timing;
            }

            &:hover,
            &:focus {
                svg {
                    background-color: $color-white;
                }
            }
        }

        &__buttons-bottom {
            margin-top: 10px;
            display: flex;
            gap: 28px;
            align-items: center;
        }

        &__template--button {
            margin: 0;
            padding: 10px 30px;
            height: 45px;
        }

        &__navigation {
            display: flex;
            gap: 10px;
        }

        .swiper-button-prev,
        .swiper-button-next {
            position: static;
            width: 40px;
            height: 40px;
            background-color: $color-second;
            padding: 0;
            border: 1px solid $color-second;
            border-radius: $border-radius--round;
            margin: 0;
            transition: all $duration ease-in-out;
            z-index: 0;

            &:hover,
            &:focus {
                background-color: $color-white;

                svg {
                    fill: $color-dark;
                }
            }

            & svg {
                width: 30px;
                height: 30px;
                fill: $color-dark;
            }

            svg {
                width: 30px;
                height: 30px;
            }

            &::after {
                display: none;
            }
        }
    }

    // ==================================
    // Social
    // ==================================

    .social {
        width: 540px;
        background-color: $color-white;
        box-shadow: 1px 1px 20px 0px rgba(40, 40, 40, 0.1);
        padding: 50px 45px;
        border-radius: $border-radius;
        position: relative;
        height: 505px;

        &::before {
            content: "";
            position: absolute;
            top: -81px;
            left: -92px;
            z-index: -1;
            width: 405px;
            height: 420px;
            background-image: url($urlSpriteImage + "/theme-icons/bulles_gardons_lien.svg");
            background-repeat: no-repeat;
            background-size: cover;
        }

        &__title {
            font-size: toRem(45);
            line-height: toRem(46);
            margin-bottom: 25px;
            z-index: 0;

            span {
                font-size: toRem(44);
                line-height: toRem(45);
                margin-left: -66px;
            }

            &::before {
                top: unset;
                width: 43px;
                height: 46px;
                bottom: 17px;
                background-image: none;
                mask-image: url($urlSpriteImage + "/theme-icons/bulle_titre_home.svg");
                mask-repeat: no-repeat;
                mask-size: cover;
                background-color: $color-third;
                z-index: -1;
                left: -15px;
            }
        }

        &__informations {
            font-family: $font-family;
            font-size: toRem(16);
            font-weight: $font-weight-normal;
            line-height: toRem(24);
            color: $color-dark;
            margin-bottom: 54px;
        }

        &__links {
            display: flex;
            align-items: center;
            // justify-content: center;
            gap: 20px;
        }

        &__link {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 130px;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: -29px;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 0;
                width: 120px;
                height: 115px;
                background-image: url($urlSpriteImage + "/theme-icons/bulle_gardons_lien.svg");
                background-size: cover;
                background-repeat: no-repeat;
                transition: transform $duration $timing;
            }

            p {
                margin-top: 43px;
                font-family: $font-family;
                font-size: toRem(15);
                font-weight: $font-weight-bold;
                line-height: 1.2;
                color: $color-dark;
            }

            &:hover,
            &:focus {
                &::before {
                    transform: rotate(45deg);
                }
            }
        }

        &__group {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__exit-icon {
            width: 26px;
            height: 26px;
        }
    }

    // ==================================
    // Admin-area-2
    // ==================================

    .admin-area-2 {
        height: 550px;
        background-image: url($urlShortImage + "/champs_commune.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        margin-top: 90px;
        // margin-bottom: 250px;

        &::before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(270deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.45) 80%);
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 41px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 0;
            width: 2100px;
            height: 205px;
            background-image: url($urlSpriteImage + "/theme-icons/ligne_commune.svg");
            background-repeat: no-repeat;
            background-size: cover;
        }

        &__container {
            position: relative;
            z-index: 1;
            padding-top: 60px;
            height: 100%;

            &::before {
                content: "";
                position: absolute;
                bottom: 0px;
                right: -450px;
                z-index: 0;
                width: 1113px;
                height: 453px;
                background-image: url($urlSpriteImage + "/theme-icons/grande_carte.svg");
                background-size: cover;
                background-repeat: no-repeat;
            }
        }

        &__title {
            color: $color-white;
            align-items: flex-start;
            text-shadow: 2px 2px 30px #00000073;

            span {
                margin-left: 40px;
                color: $color-white;
            }

            &::before {
                top: unset;
                bottom: 21px;
                width: 43px;
                height: 46px;
                left: -15px;
                opacity: 0.5;
            }

            &--small {
                margin: 0;
                font-family: $font-family;
                font-size: toRem(50);
                font-weight: $font-weight-bold;
                line-height: toRem(55);
                color: $color-white;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    bottom: 2px;
                    left: -5px;
                    z-index: -1;
                    width: 24px;
                    height: 26px;
                    background-image: url($urlSpriteImage + "/theme-icons/bulle_chiffre.svg");
                    background-repeat: no-repeat;
                    background-size: cover;
                    opacity: 0.5;
                    transition: opacity $duration $timing;
                }
            }
        }

        &__item {
            text-shadow: 2px 2px 30px #00000073;

            &:hover,
            &:focus {
                a {
                    .admin-area-2__title--small {
                        &::before {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &__intro {
            width: 430px;
            font-family: $font-family;
            font-size: toRem(16);
            font-weight: $font-weight-bold;
            line-height: toRem(22);
            color: $color-white;
            margin-top: 30px;
            text-shadow: 2px 2px 30px #00000073;
        }

        &__list {
            display: flex;
            gap: 65px;
            margin-top: 50px;
        }

        &__link {
            display: flex;
            flex-direction: column;
        }

        &__description {
            font-family: $font-family;
            font-size: toRem(20);
            font-weight: $font-weight-semibold;
            line-height: toRem(25);
            color: $color-white;
            letter-spacing: 0.2px;
            text-transform: uppercase;
        }

        &__plan {
            width: fit-content;
            position: absolute;
            top: 87px;
            right: 115px;
        }

        &__plan-link {
            position: relative;
            height: fit-content;
            width: fit-content;
            display: block;
        }

        &__btn {
            position: absolute;
            top: 167px;
            left: 5px;
            background-color: $color-white;
            margin: 0;
            font-family: $font-family;
            font-size: toRem(15);
            font-weight: $font-weight-bold;
            color: $color-dark;
            border: none;
            height: 45px;
            padding: 15px 0px;
            width: 180px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            transition: background-color $duration $timing;

            svg {
                width: 26px;
                height: 26px;
            }

            &:hover,
            &:focus {
                background-color: $color-main;
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .front-page {
        &__title {
            font-size: toRem(40);
            line-height: toRem(50);

            span {
                font-size: toRem(40);
                line-height: toRem(40);
            }
        }

        // ==================================
        // Slideshow
        // ==================================

        .slideshow {
            margin-top: -100px;
            height: 490px;
        }

        // ==================================
        // Access
        // ==================================

        .access {
            margin-top: -87px;
            &__container {
                gap: 23px;
            }

            &__big-item {
                margin-left: 0;
            }

            &__item-list {
                gap: 30px;
            }

            &__icon-container {
                &::after {
                    width: 50px;
                    height: 50px;
                    right: -6.68px;
                    bottom: -3.63px;
                }
            }
        }

        // ==================================
        // News
        // ==================================

        .news {
            margin-top: 46px;
            &::before {
                height: 616px;
                top: 194px;
            }

            &::after {
                width: 1400px;
                height: 148.16px;
                top: 616px;
            }

            &__deco {
                transform: translateX(-416px - 85px);
                top: 427px;
            }

            &__big-image {
                width: calc(50% + 95px);
                height: 345px;
                margin-left: -45px;
            }

            &__big-content {
                margin-bottom: 25px;
                width: calc(50% - 50px);
                padding: 40px 30px;
            }

            &__swiper {
                padding: 40px 0 31px;
            }

            &__buttons {
                margin-top: 9px;
            }

            &__big-item {
                margin-top: -68px;
            }

            &__item {
                padding: 30px 30px;
            }
        }

        // ==================================
        // Admin-area-1
        // ==================================
        .admin-area-1 {
            &__container {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                &::before {
                    right: -93px;
                    top: 86px;
                }
            }
            &__title {
                position: relative;
                // right: 0;
                right: 180px;
                top: 4px;

                span {
                    margin-right: -35px;
                }
            }

            &__blocs {
                margin-top: 11px;
            }

            .bloc {
                &__image {
                    width: calc(50% + 49px);
                    height: 320px;
                }

                &__content {
                    width: calc(50% - 15px);
                    margin-left: -34px;
                    margin-top: 22px;
                    padding: 30px 30px;
                }
            }
        }

        // ==================================
        // Events
        // ==================================

        .events {
            margin-top: 48px;
            &::before {
                height: 440px;
                top: 195px;
            }

            &.no-image {
                margin-top: 138px;
            }

            &__title {
                margin-bottom: 30px;
                span {
                    margin-left: 24px;
                }
            }

            &::after {
                width: 1400px;
                height: 136px;
                top: 421px;
            }

            &__image {
                width: 450px;
                height: 280px;
            }

            &__date {
                top: 230px;
            }
        }

        .kiosque-social-group {
            margin-top: 153px;
        }

        // ==================================
        // Kiosque
        // ==================================
        .kiosque {
            width: 434px;

            &__image {
                width: 220px;
                height: 310px;
            }

            &__title {
                margin-bottom: 15px;
            }

            &__buttons {
                gap: 10px;
            }

            &__extension {
                margin-top: 6px;
            }

            &__item {
                gap: 20px;
            }

            &__buttons-bottom {
                margin-top: 25px;
            }

            &__slide-content {
                padding-top: 0;
            }

            .swiper-container {
                height: 340px;
            }

            &__list {
                height: 310px;
            }

            &__template--button {
                padding: 10px 25px;
            }
        }

        // ==================================
        // Social
        // ==================================
        .social {
            width: 50%;
            padding: 40px 39px;

            &::before {
                display: none;
            }

            &__title {
                font-size: toRem(35);
                line-height: toRem(46);

                span {
                    font-size: toRem(40);
                    line-height: toRem(40);
                    margin-left: 0;
                }
            }
        }

        // ==================================
        // Admin-area-2
        // ==================================

        .admin-area-2 {
            margin-top: 79px;

            &::after {
                width: 1400px;
                height: 137px;
                bottom: 20px;
            }

            &__container {
                padding-top: 65px;

                &::before {
                    width: 970px;
                    height: 395px;
                    right: -390px;
                }
            }

            &__plan {
                right: 102px;
            }

            &__list {
                gap: 45px;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .front-page {
        // ==================================
        // Slideshow
        // ==================================

        .slideshow {
            margin-top: -100px;
            height: 330px;

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }
        }

        // ==================================
        // Access
        // ==================================

        .access {
            margin-top: -37px;

            &__container {
                flex-direction: column;
                align-items: flex-start;
                gap: 30px;
            }

            &__big-item {
                width: 350px;
            }

            &__big-link {
                flex-direction: row;
                gap: 24px;
                width: unset;
                align-items: flex-end;

                p {
                    margin-bottom: 8px;
                    align-items: flex-start;

                    span {
                        margin-top: -18px;
                    }
                }
            }

            &__big-icon-container {
                width: 124px;
                height: 124px;

                &::before {
                    width: 121px;
                    height: 116px;
                }

                &::after {
                    width: 61px;
                    height: 57px;
                    z-index: -1;
                }
            }

            &__item-list {
                width: 100%;
                padding-right: 40px;
                gap: 0;
                justify-content: space-between;
            }
        }

        // ==================================
        // News
        // ==================================

        .news {
            margin-top: 10px;
            &::before {
                height: 800px;
                top: 274px;
            }

            &::after {
                top: 866px;
                display: none;
            }

            &__deco {
                transform: translateX(0);
                left: 18px;
                top: 720px;
            }

            &__big-item {
                margin-top: 30px;
            }

            &__big-link {
                flex-direction: column-reverse;
                align-items: center;
            }

            &__big-image {
                width: 100%;
                height: 400px;
                margin-left: 0;
            }

            &__buttons {
                margin-top: 0px;
            }

            &__big-content {
                width: calc(660 / 700 * 100%);
                margin-top: -40px;
                margin-bottom: 0;
                padding: 40px 30px 51px;
            }

            &__swiper {
                padding: 30px 0;
            }
        }

        // ==================================
        // Admin-area-1
        // ==================================

        .admin-area-1 {
            margin-bottom: 60px;
            margin-top: 116px;

            &__container {
                align-items: flex-start;

                &__container {
                    &::before {
                        right: -63px;
                        top: 347px;
                    }
                }
            }

            &__title {
                right: unset;
            }

            &__blocs {
                margin-top: 34px;
            }

            .bloc {
                align-items: flex-start;

                &__wrapper {
                    flex-direction: column;
                    align-items: center;
                }

                &__image {
                    width: 100%;
                    height: 400px;
                }

                &__content {
                    width: calc(660 / 700 * 100%);
                    margin-top: -90px;
                    margin-left: 0;
                    padding: 30px 40px;
                }

                &__link {
                    margin-top: 39px;
                }
            }
        }

        // ==================================
        // Events
        // ==================================

        .events {
            &::before {
                height: 350px;
                top: 195px;
            }

            &::after {
                top: 358px;
                height: 139px;
            }

            &__title {
                &--small {
                    margin-top: 25px;
                    width: calc(100% - 10px);
                }
            }

            &__image {
                width: calc(100% - 10px);
                height: 205px;
            }

            &__buttons {
                margin-top: 50px;
            }

            &__date {
                top: 160px;
            }
        }

        .kiosque-social-group {
            margin-top: 130px;
            &__container {
                flex-direction: column;
                align-items: flex-start;
                gap: 60px;
            }
        }

        // ==================================
        // Social
        // ==================================

        .social {
            width: 100%;

            &__title {
                span {
                    line-height: 2rem;
                }

                &::before {
                    bottom: 13px;
                    left: -16px;
                }
            }

            &__links {
                gap: 15px;
            }

            &__informations {
                margin-bottom: 57px;
            }

            &__link {
                width: 120px;
            }
        }

        // ==================================
        // Admin-area-2
        // ==================================

        .admin-area-2 {
            height: 570px;
            margin-top: 30px;

            &::after {
                width: 1400px;
                height: 136.67px;
                left: -100px;
                transform: translateX(0);
                bottom: 32px;
            }

            &__title {
                margin: 0 20px;
            }

            &__intro {
                margin-left: 5px;
            }

            &__list {
                margin-left: 5px;
            }

            &__container {
                &::before {
                    display: none;
                }
            }

            &__plan {
                position: static;
            }

            &__plan-link {
                position: static;

                > svg:first-of-type {
                    display: none;
                }
            }

            &__btn {
                position: static;
                margin-top: 45px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .front-page {
        // ==================================
        // Access
        // ==================================

        .access {
            margin-top: -28px;

            &__big-item {
                margin-left: -10px;
            }

            &__item-list {
                padding-right: 0;
                gap: 0px;
                flex-wrap: wrap;
            }
        }

        // ==================================
        // News
        // ==================================

        .news {
            &__big-image {
                height: 200px;
            }

            &__big-content {
                width: calc(536 / 556 * 100%);
            }

            &__item {
                height: 240px;
            }

            &__deco {
                top: 517px;
            }

            &__swiper {
                padding: 28px 0;
            }

            &__link {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &__buttons {
                flex-direction: column-reverse;
                gap: 20px;
            }

            &__button {
                width: 225px;
                padding: 8.5px 0;
            }
        }

        // ==================================
        // Admin-area-1
        // ==================================

        .admin-area-1 {
            margin-top: 106px;
            .bloc {
                &__image {
                    height: 200px;
                }

                &__content {
                    margin-top: -30px;
                    padding: 16px 25px;
                    width: calc(536 / 556 * 100%);
                }

                &__link {
                    margin-top: 30px;
                }
            }
        }

        // ==================================
        // Events
        // ==================================

        .events {
            &::before {
                height: 350px;
            }
            &::after {
                display: none;
            }
            &__image {
                width: 100%;
                height: 190px;
            }

            &__date {
                top: 145px;
            }

            &__title {
                &--small {
                    width: 100%;
                }
            }

            &__buttons {
                flex-direction: column-reverse;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 20px;
                margin-top: 30px;
            }
        }

        .kiosque-social-group {
            margin-top: 110px;
        }

        // ==================================
        // Kiosque
        // ==================================

        .kiosque {
            width: 100%;
            &__buttons-bottom {
                flex-direction: column-reverse;
                align-items: flex-start;
                gap: 20px;
                margin-top: 25px;
            }

            &__title {
                margin-bottom: 30px;
                span {
                    margin-left: -17px;
                }
            }

            .swiper-container {
                height: unset;
                max-width: 100%;
            }

            &__list {
                height: unset;
            }

            &__item {
                flex-direction: column;
                gap: 20px;
            }

            &__item-title {
                width: 100%;
            }
        }

        // ==================================
        // Social
        // ==================================

        .social {
            padding: 30px 25px 60px;
            height: unset;
            &__links {
                flex-direction: column;
                align-items: flex-start;
                gap: 72px;
            }

            &__title {
                margin-left: 15px;
                margin-right: 0;
                margin-bottom: 21px;

                &::before {
                    bottom: unset;
                    top: 15px;
                }
                span {
                    line-height: toRem(24);
                }
            }

            &__group {
                align-items: flex-start;

                p {
                    margin-top: 0;
                    width: 120px;
                }
            }

            &__links {
                gap: 69px;
            }

            &__link {
                flex-direction: row;
                gap: 52px;
                width: 300px;
                align-items: flex-end;

                &::before {
                    left: 0;
                    right: unset;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                }

                svg:not(.social__exit-icon) {
                    margin-left: 30px;
                }
            }
        }

        // ==================================
        // admin-area-2
        // ==================================

        .admin-area-2 {
            height: auto;
            margin-top: 62px;

            &::after {
                display: none;
            }

            &__list {
                flex-direction: column;
                gap: 20px;
                align-items: flex-start;
            }

            &__container {
                padding-top: 55px;
                padding-bottom: 62px;
            }

            &__title {
                width: 295px;
                line-height: 40px;
                margin: 0 15px;

                &::before {
                    bottom: 51px;
                }
            }

            &__list {
                margin-top: 20px;
                margin-left: 5px;
            }

            &__btn {
                padding: 15px 20px;
                width: 215px;
            }

            &__intro {
                width: 100%;
                margin-left: 0;
                margin-top: 20px;
            }

            &__btn {
                margin-top: 30px;
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .front-page {
        .events {
            &::before {
                height: 360px;
            }
        }
    }
}

@media screen and (max-width: $x-small) {
    .front-page {
        .access {
            &__big-item {
                margin-left: 0;
            }

            &__item-list {
                gap: 23px 0;
            }
        }

        .news {
            &::before {
                top: 247px;
            }
            &__big-content {
                padding: 14px 27px 51px;
                width: calc(280 / 300 * 100%);
            }

            &__item {
                padding: 27px;
            }

            &__buttons {
                margin-top: -2px;
            }
        }

        .admin-area-1 {
            .bloc {
                &__content {
                    width: calc(280 / 300 * 100%);
                    padding: 16px 27px;
                }
            }
        }

        .kiosque {
            width: 100%;

            &__date {
                margin-top: 10px;
            }
        }

        .social {
            padding: 30px 25px 65px;
            &__title {
                width: 206px;
                line-height: toRem(34);
                margin-bottom: 25px;

                &::before {
                    bottom: 39px;
                    top: unset;
                }

                span {
                    line-height: 1.4rem;
                    margin-bottom: 9px;
                    margin-left: 42px;
                }
            }

            &__informations {
                margin-bottom: 55px;
            }
        }

        .admin-area-2 {
            &__title {
                margin: 0px 17px;

                &::before {
                    bottom: 41px;
                }
            }

            &__intro {
                width: calc(100% - 9px);
            }

            &__list {
                margin-left: 6px;
                margin-top: 33px;
            }
        }
    }
}
