header {
    position: relative;
}

body:not(.home) {
    .header {
        box-shadow: 1px 1px 20px 0px #2828281a;
    }
}

body.home {
    .header {
        .identity {
            &__title {
                margin: 0;
                height: 75px;

                svg {
                    width: 200px;
                    height: 75px;

                    .st4 {
                        fill: $color-white;
                    }
                }
            }
        }

        &--fixed {
            .identity {
                &__title {
                    svg {
                        .st4 {
                            fill: $color-dark;
                        }
                    }
                }
            }
        }
    }
}

//======================================================================================================
// Header Fixed
//======================================================================================================

@keyframes headerFixed {
    from {
        top: -100px;
    }
    to {
        top: 0;
    }
}

.header--fixed ~ main {
    margin-top: 130px;
}

.header {
    height: 130px;
    background-color: transparent;
    z-index: 15;

    .identity {
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.55);
    }

    .tools {
        text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.55);
    }

    &.header--fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: $color-white;
        animation: 1s headerFixed;
        transition: 0.35s;
        box-shadow: $shadow;
        height: 100px;
    }

    &__wrapper {
        justify-content: space-between;
    }

    .container {
        height: 100%;
        display: flex;
        align-items: center;
        // justify-content: space-between;
    }

    &__group {
        &-container {
            margin: 0;
            max-width: unset;
            padding: 0;
            justify-content: space-between;
        }
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

//======================================================================================================
// Identity
//======================================================================================================
.identity {
    @include flex($alignItems: center, $justifyContent: center);
    @include size(fit-content);
    color: $color-text;
    transition: color $duration $timing;

    &:hover,
    &:focus {
        color: $color-second;
    }

    &__title {
        margin: 0;
        height: 75px;

        svg {
            width: 200px;
            height: 75px;

            .st4 {
                fill: $color-dark;
            }
        }
    }
}

//======================================================================================================
// Tools
//======================================================================================================
.tools {
    margin-left: 40px;
    &__container {
        display: flex;
        justify-content: flex-end;
    }

    &__content {
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-bg--transparent;
        pointer-events: auto;
        gap: 10px;
    }

    &__item {
        @include flex($justifyContent: center, $alignItems: center);
        @include size(40px, 40px);
        padding: 0;
        background-color: $color-main;
        border: 1px solid $color-main;
        border-radius: $border-radius--round;
        cursor: pointer;
        margin: 0;
        position: relative;

        svg {
            @include size(30px);
            fill: $color-text;
            transition: fill $duration $timing;
        }

        &:hover,
        &:focus {
            background-color: #fff;
            border: 1px solid $color-main;

            svg {
                fill: $color-dark;
            }
        }

        &--search {
            &--disabled {
                pointer-events: none;
                opacity: 0.2;
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    body:not(.home) {
        .header {
            height: 100px;
            .tools {
                margin-left: 15px;
            }
            .identity {
                &__title {
                    margin: 0;
                    height: 53px;

                    svg {
                        width: 140px;
                        height: 53px;
                    }
                }
            }
        }
    }

    body.home {
        .header {
            height: 100px;
            .tools {
                margin-left: 15px;
            }
            .identity {
                width: 140px;
                height: 53px;
                &__title {
                    margin: 0;
                    height: 53px;

                    svg {
                        width: 140px;
                        height: 53px;
                    }
                }
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .header {
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(75px + 40px);
        &__group {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100vw;
            background-color: $color-white;
            height: 60px;
            z-index: 1;
            justify-content: space-between;
            box-shadow: 1px 1px 20px 0px #2828281a;

            &-container {
                margin: 0 auto;
                max-width: 90%;
                padding: 0 10px;
                width: 100%;
            }
        }

        .identity {
            width: 200px;
            height: 75px;
            &__title {
                margin: 0;
                width: 200px;
                height: 75px;

                svg {
                    width: 200px;
                    height: 75px;
                }
            }
        }

        &--fixed {
            position: static;
        }
    }

    body.home {
        .header {
            .identity {
                width: 200px;
                height: 75px;
                margin-top: 35px;
                &__title {
                    margin: 0;
                    width: 200px;
                    height: 75px;

                    svg {
                        width: 200px;
                        height: 75px;
                    }
                }
            }
        }
    }

    body:not(.home) {
        .header {
            background-color: $color-white;
            display: flex;
            justify-content: center;
            align-items: center;
            height: calc(75px + 40px);

            .identity {
                &__title {
                    margin: 0;
                    height: 75px;

                    svg {
                        width: 200px;
                        height: 75px;
                    }
                }
            }
        }
    }
}
