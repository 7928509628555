.footer {
    padding-top: 44px;

    &__container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 38px;
    }

    &__group {
        display: flex;
        align-items: center;
    }

    &__logo {
        width: 210px;
        height: 79px;

        .st4 {
            fill: $color-dark;
        }
    }

    &__infos {
        margin-left: 51px;
        width: 216px;
        display: flex;
        flex-direction: column;
    }

    &__opening-hours {
        margin-left: 79px;
        margin-top: 12px;
        max-width: 400px;
    }

    &__text {
        margin-bottom: 12px;
        font-family: $font-family;
        font-size: toRem(16);
        font-weight: $font-weight-normal;
        line-height: toRem(24);
        color: $color-dark;
    }

    &__phone {
        font-family: $font-family;
        font-size: toRem(16);
        font-weight: $font-weight-bold;
        line-height: toRem(24);
        color: $color-dark;
        display: flex;
        align-items: center;

        &--astreinte {
            font-family: $font-family;
            font-size: toRem(14);
            font-weight: $font-weight-normal;
            line-height: toRem(24);
            color: $color-gray;
        }

        svg {
            width: 26px;
            height: 26px;
        }
    }

    &__title {
        font-family: $font-family;
        font-size: toRem(18);
        font-weight: $font-weight-bold;
        line-height: toRem(24);
        color: $color-dark;
        margin-bottom: 12px;
    }

    &__menu {
        height: 65px;
        padding: 23.5px 0;
        border-top: 1px solid $color-main;
        &__list {
            display: flex;
            justify-content: space-between;
            // flex-wrap: wrap;
        }

        &__link {
            font-family: $font-family;
            font-size: toRem(14);
            font-weight: $font-weight-normal;
            line-height: toRem(18);
            color: $color-dark;
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: text-decoration-color 0.3s ease-in-out;

            &:hover,
            &:focus {
                text-decoration-color: $color-main;
                text-decoration-thickness: 2px;
            }
        }
    }

    &__btn {
        background-color: $color-third;
        border: 1px solid $color-third;
        margin: 29px 0 0;
        font-family: $font-family;
        font-size: toRem(15);
        font-weight: $font-weight-bold;
        color: $color-dark;
        height: 45px;
        padding: 15px 24px;

        &:hover,
        &:focus {
            background-color: transparent;
            border: 1px solid $color-third;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .footer {
        &__logo {
            width: 185px;
            height: 70px;
        }
        &__infos {
            margin-left: 30px;
        }

        &__opening-hours {
            margin-left: 40px;
            max-width: 270px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        padding-top: 55px;
        margin-bottom: 40px;
        &__group {
            flex-direction: column;
        }

        &__container {
            flex-direction: column;
            align-items: center;
            margin-bottom: 52px;
        }

        &__text {
            margin-bottom: 10px;
        }

        &__infos {
            margin-left: 0;
            margin-top: 20px;
            text-align: center;
        }

        &__phone {
            text-align: center;
            display: flex;
            justify-content: center;
        }

        &__opening-hours {
            margin-left: 0;
            text-align: center;
            margin-top: 30px;
        }

        &__btn {
            margin-top: 10px;
            width: 200px;
        }

        &__menu {
            height: auto;
            padding: 30px 0;

            &__list {
                flex-wrap: wrap;
                justify-content: center;
                gap: 15px 30px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        &__menu {
            padding: 21px 0;
        }
    }
}

// 360
@media screen and (max-width: $x-small) {
    .footer {
        &__btn {
            width: 187px;
        }
    }
}
