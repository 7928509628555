.cover {
    background-color: $color-blue-light;
    padding-bottom: 80px;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 260px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
        width: 2100px;
        height: 200px;
        background-image: url($urlSpriteImage + "/theme-icons/CSS/ligne_top_post.svg");
        background-repeat: no-repeat;
        background-size: contain;
    }

    &__container {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 50px;
            right: -105px;
            z-index: 0;
            width: 290px;
            height: 365px;
            background-image: url($urlSpriteImage + "/theme-icons/CSS/bulles_top_post.svg");
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        position: relative;
    }

    &__image-wrapper {
        width: calc(50% + 25px);
        height: 350px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__informations {
        width: calc(50% + 5px);
        margin-left: -30px;
        background-color: $color-white;
        border-radius: 20px;
        padding: 40px;
        min-height: 250px;
        display: flex;
        align-items: center;
    }

    &__title {
        margin: 0;
        font-family: $font-family;
        font-size: toRem(38);
        font-weight: $font-weight-bold;
        line-height: toRem(41);
        color: $color-dark;
        position: relative;
        z-index: 0;

        &::before {
            content: "";
            position: absolute;
            top: -11.5px;
            left: -15px;
            z-index: 0;
            width: 35px;
            height: 37px;
            background-image: url($urlSpriteImage + "/theme-icons/CSS/bulle_h1.svg");
            background-size: contain;
            background-repeat: no-repeat;
            z-index: -1;
        }
    }

    &__intro {
        margin-top: 10px;
        font-family: $font-family;
        font-size: toRem(18);
        font-weight: $font-weight-bold;
        line-height: toRem(24);
        color: $color-dark;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {
    .cover {
        padding-bottom: 20px;
        &__container {
            display: flex;
            flex-direction: column-reverse;
        }

        &__content {
            flex-direction: column;
            align-items: center;
        }

        &__image-wrapper {
            height: 330px;
            width: 100%;
            margin-top: 40px;
        }

        &__informations {
            width: 95%;
            margin: 0;
            margin-top: -20px;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }

        &__image-wrapper {
            height: 255px;
        }
    }
}
