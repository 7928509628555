.header {
    z-index: 15;
    &.header--fixed {
        .nav-main {
            .menu__item.menu-item-has-children.js-open-submenu {
                .submenu {
                    top: 100px;
                }

                .menu__link {
                    color: $color-dark !important;
                    &::before {
                        bottom: 4px;
                    }
                }
            }

            .submenu {
                top: 100px;
            }
        }
    }
    .nav-main {
        z-index: 10;

        // Container menu
        &__container {
            @include flex($justifyContent: space-between, $alignItems: center);
        }

        // Main menu
        .menu {
            display: flex;
            gap: 30px;

            &__item {
                &.menu-item-has-children.js-open-submenu {
                    .submenu {
                        opacity: 1;
                        visibility: visible;
                        pointer-events: inherit;
                    }

                    .menu__link {
                        color: $color-text;

                        &:before {
                            opacity: 1;
                            bottom: -11px;
                        }

                        &::after {
                            opacity: 0.5;
                        }
                    }
                }

                &:hover,
                &:focus {
                    .menu__link {
                        &::after {
                            opacity: 0.5;
                        }
                    }
                }
            }

            &__link {
                position: relative;
                @include flex($alignItems: center);
                max-width: unset;
                height: fit-content;
                padding: 55px 0;
                font-size: toRem(16);
                line-height: toRem(20);
                background-color: $color-bg--transparent;
                color: $color-dark;
                text-transform: uppercase;
                font-weight: $font-weight-black;
                transition: {
                    property: background-color, color;
                    duration: $duration;
                    timing-function: $timing;
                }

                &::before {
                    content: "";
                    @include absolute($right: 0, $bottom: -15px, $left: 0);
                    @include size(21px);
                    border-radius: 5px 0 0 0;
                    transform: rotate(45deg);
                    clip-path: polygon(0 0, 0% 100%, 100% 0);
                    opacity: 0;
                    margin: 0 auto;
                    background-color: $color-light;
                    transition: {
                        property: bottom, opacity;
                        duration: $duration;
                        timing-function: $timing;
                    }
                }

                &::after {
                    content: "";
                    width: 18px;
                    height: 20px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -7px;
                    background-image: url($urlSpriteImage + "/theme-icons/CSS/bulle_menu.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    z-index: -1;
                    opacity: 0;
                    transition: opacity $duration $timing;
                }

                span {
                    font-weight: $font-weight-medium;
                    margin-right: 4px;
                }
            }
        }

        // Sub menu
        .submenu {
            @include flex($justifyContent: center);
            position: absolute;
            right: 0;
            z-index: 1;
            width: 100%;
            padding: 50px 15px 44px;
            background-color: $color-bg--neutral;
            box-shadow: $shadow;
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            top: 130px;
            transition: {
                property: visibility, opacity, pointer-events;
                duration: $duration;
                timing-function: $timing;
            }

            &__nav {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 0 30px;
            }

            &__item {
                display: inline-flex;
                width: 100%;
                margin: -1px; // Fix space beetween inline-flex
                padding: 0;
                line-height: initial;
            }

            &__link {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                height: 50px;
                padding: 15px 30px 15px 0;
                border-bottom: 1px solid $color-dark--rgba5;
                color: $color-text;
                font-weight: $font-weight-medium;

                span {
                    font-family: $font-family;
                    font-size: toRem(16);
                    font-weight: $font-weight-bold;
                    line-height: toRem(24);
                    color: $color-dark;
                    transition: {
                        property: transform;
                        duration: $duration;
                        timing-function: $timing;
                    }
                }

                &::before {
                    content: "";
                    display: inherit;
                    position: absolute;
                    bottom: -1px;
                    width: 0;
                    height: 2px;
                    background-color: $color-main;
                    transition: {
                        property: width, transform;
                        duration: $duration;
                        timing-function: $timing;
                    }
                }

                &::after {
                    content: "\f345";
                    font: normal 20px/1 dashicons;
                    position: absolute;
                    right: 0;
                    color: $color-dark;
                    opacity: 0.5;
                    transition: opacity $duration $timing;
                }

                &:hover,
                &:focus {
                    span {
                        transform: translateX(10px);
                    }

                    &::before {
                        width: 100%;
                    }

                    &::after {
                        color: $color-dark;
                        opacity: 1;
                    }
                }

                &--grandchild {
                    display: flex;
                    padding: 17px 10px 17px 15px;
                    border-bottom: 0;
                    font-size: $font-size--text-small;
                    font-weight: $font-weight;
                    text-transform: initial;
                    line-height: initial;

                    span {
                        font-family: $font-family;
                        font-size: toRem(16);
                        font-weight: $font-weight-normal;
                        line-height: toRem(24);
                        color: $color-dark;
                    }

                    &::before {
                        display: none;
                    }
                }
            }

            &__close {
                @include absolute($top: 0px);
                @extend %SpaceFull-to-Container--right;
                margin: 0;
                padding: 0;
                background-color: $color-bg--transparent;
                font-size: $font-size--text-small;
                font-weight: $font-weight-bold;
                text-transform: uppercase;

                &__icon {
                    display: block;
                    @include size(30px);
                    background-color: $color-white;
                    // border: $btn-border-width $btn-border-style $btn-border-color;
                    border-radius: $border-radius--round;
                    fill: $color-dark;
                }

                &__mobile {
                    display: none;
                }

                svg {
                    transition: {
                        property: background-color, fill;
                        duration: $duration;
                        timing-function: $timing;
                    }
                }

                &:hover,
                &:focus {
                    .submenu__close__icon {
                        background-color: $color-dark;
                        fill: $color-white;
                    }
                }
            }
        }

        // For responsive
        &__button,
        &__icon,
        &__close {
            display: none;
        }
    }

    &.header--fixed {
        .nav-main {
            .menu__link {
                color: $color-dark;
            }
        }
    }
}

body.home {
    .header {
        .nav-main {
            .menu__item {
                &.menu-item-has-children.js-open-submenu .menu__link {
                    color: $color-white;
                }

                .menu__link {
                    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.55);
                    color: $color-white;
                }
            }
        }

        &.header--fixed {
            text-shadow: none;
            .nav-main {
                .menu__link {
                    color: $color-dark;
                    text-shadow: none;
                }
            }
        }
    }
}
//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header {
        .nav-main {
            .menu {
                gap: 15px;

                &__item {
                    &.menu-item-has-children.js-open-submenu .menu__link::before {
                        bottom: 19px;
                    }
                }

                &__link {
                    font-size: toRem(15);

                    &::before {
                        bottom: 19px;
                    }
                }
            }

            .submenu {
                top: 85px;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .header {
        // if menu open (no scroll on body)
        body.js-open-menu {
            overflow: hidden;
        }

        .nav-main {
            position: relative;

            div:nth-child(1) {
                margin: 0;
                padding: 0;
            }

            // If menu open
            &.js-open-menu {
                // Container menu
                .nav-main__container {
                    left: 0;
                }
            }

            // Container menu
            &__container {
                position: fixed;
                top: 0;
                left: -100vw;
                right: 100%;
                z-index: 990;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                row-gap: 15px;
                max-width: inherit;
                width: 100%;
                height: 100vh;
                background-color: $color-main;
                padding: 40px 0;
                transition: left $duration $timing;
            }

            // Burger menu responsive - Open menu
            &__button {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: $font-family;
                font-size: toRem(15);
                font-weight: $font-weight-extrabold;
                line-height: toRem(22);
                color: $color-dark;
                background-color: transparent;
                border: none;
                text-transform: uppercase;
                padding: 0;
                margin: 0;

                &:hover,
                &:focus {
                    color: $color-dark;

                    .nav-main__icon {
                        background-color: $color-second;
                    }
                }
            }

            // Button icon (Burger menu responsive - Open menu)
            &__icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                background-color: $color-third;
                border-radius: $border-radius--round;
                transition: background-color $duration $timing;

                svg {
                    fill: $color-dark;
                }
            }

            // Burger menu responsive - Close menu
            &__close {
                position: relative;
                z-index: 10;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                min-height: 40px;
                margin: 0 40px 0 auto;
                padding: 10px;
                background-color: transparent;
                border: $btn-border-width $btn-border-style $color-white;
                border-radius: $border-radius--round;

                svg {
                    width: 12px;
                    height: 12px;
                    margin: 0;
                    fill: $color-dark;
                }

                &:hover,
                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        fill: $color-dark;
                    }
                }
            }

            // Main menu
            .menu {
                flex-direction: column;
                flex-wrap: nowrap;
                overflow-y: auto;
                width: 100%;
                padding: 0 40px;

                &__item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100% !important;
                    border-bottom: 1px solid rgb(255 255 255 / 30%);

                    &.menu-item-has-children {
                        &::after {
                            content: "\f345";
                            font: normal 20px/1 dashicons;
                            color: $color-dark;
                            transition: none;
                        }

                        // If sub menu open
                        &.js-open-submenu {
                            .submenu {
                                right: 0;
                            }

                            .menu__link {
                                background-color: $color-bg--transparent;
                                color: $color-dark;
                            }
                        }
                    }

                    .menu__link {
                        color: $color-dark !important;
                        text-shadow: none !important;
                    }

                    &:hover,
                    &:focus {
                        .menu__link {
                            background-color: $color-bg--transparent;
                            color: $color-dark;
                        }
                    }
                }

                &__link {
                    justify-content: flex-start !important;
                    max-width: inherit;
                    width: 100%;
                    height: auto;
                    padding: 20px 0;
                    color: $color-dark;
                    transition: none;
                    transition: transform $duration $timing;

                    &::after {
                        display: none;
                    }

                    &:hover,
                    &:focus {
                        background-color: $color-bg--transparent;
                        color: $color-dark;
                        transform: translateX(15px);
                    }
                }
            }

            // Sub menu
            .submenu {
                left: inherit;
                top: 0;
                right: 100%;
                z-index: 1;
                @include flex($direction: column, $justifyContent: flex-start);
                row-gap: 30px;
                @include size(100vw, 100vh);
                padding: 40px 0 60px 0;
                background-color: $color-main;
                transition: {
                    property: visibility, pointer-events, right;
                    duration: $duration;
                    timing-function: $timing;
                }
                opacity: 1;

                &::after {
                    display: none;
                }

                // Burger menu responsive - Close submenu
                &__close {
                    position: static;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    width: 40px;
                    height: 40px;
                    margin: 0 0 0 40px;
                    padding: 0;
                    background-color: transparent;
                    border: $btn-border-width $btn-border-style $color-white;
                    border-radius: $border-radius--round;
                    order: 1;

                    &:hover,
                    &:focus {
                        background-color: $color-white;
                        border-color: $color-white;

                        svg {
                            fill: none;
                            stroke: $color-dark;
                        }
                    }

                    &__mobile {
                        display: block;
                        width: 40px;
                        height: 40px;
                        fill: none;
                        stroke: $color-dark;
                        stroke-width: 2px;
                    }

                    &__icon,
                    span {
                        display: none;
                    }
                }

                &__nav {
                    display: flex;
                    flex-direction: column;
                    max-width: 100%;
                    padding: 0 40px;
                    overflow-y: auto;
                    order: 2;
                }

                &__item {
                    // margin: auto;
                }

                &__link {
                    color: $color-white;

                    &::after {
                        display: none;
                    }
                }
            }
        }

        // Admin
        .admin-bar {
            .nav-main {
                &__container,
                .submenu {
                    padding-top: 70px;
                }
            }
        }
    }
}
